import './styles/app.scss';

const { Application } = require('@hotwired/stimulus');
const { definitionsFromContext } = require('@hotwired/stimulus-webpack-helpers');

window.Stimulus = Application.start();
window.Stimulus.debug = process.env.NODE_ENV === 'development';

const context = require.context('./controllers', true, /\.js$/);
window.Stimulus.load(definitionsFromContext(context));
